<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ $t("navbar.products") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="pictureInput"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          selected._extended.static
                            ? selected._extended.static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :custom-strings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        drag: this.$t('product_photo')
                      }"
                      @change="onPicture"
                    >
                    </picture-input>
                    <small style="text-align: center; font-size: 11px; "
                      >5MB max.</small
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.code"
                      v-bind:label="$t('brands.id_product_brand')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.pro_ean"
                      label="EAN"
                      required
                      :rules="proEanRules"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.productType"
                      v-bind:label="$t('Type')"
                      :items="productTypeValues"
                      item-text="label"
                      item-value="value"
                      filled
                    ></v-autocomplete>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="eanPictureInput"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          selected._ean_extended.static
                            ? selected._ean_extended.static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :custom-strings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        drag: `${this.$t('brands.Photos')} EAN`
                      }"
                      @change="onPictureEan"
                    >
                    </picture-input>
                    <small style="text-align: center; font-size: 11px; ">
                      5MB max.
                    </small>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.units_per_fraction"
                      v-bind:label="$t('brands.units_per_fraction')"
                      :type="'number'"
                      required
                      :rules="unitsPerFractionRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.units_per_box"
                      v-bind:label="$t('brands.units_per_box')"
                      :type="'number'"
                      required
                      :rules="unitsPerBoxRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.vat_code"
                      v-bind:label="$t('brands.vat')"
                      required
                      :rules="vatCodeRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.centimeters"
                      v-bind:label="$t('centimeters')"
                      :type="'number'"
                      required
                      :rules="centimetersRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.productDepth"
                      v-bind:label="$t('productDepth')"
                      :type="'number'"
                      required
                      :rules="centimetersRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.outOfStock"
                      v-bind:label="$t('Out of Stock')"
                      :items="outOfStockTypeValues"
                      item-text="label"
                      item-value="value"
                      filled
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h6>{{ $t("Sales Arguments") }}</h6>
                    <vue-editor
                      v-model="selected.salesArguments"
                      :editorOptions="vue2EditorOptionsWithEmojis"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h6>{{ $t("Execution Instruction") }}</h6>
                    <vue-editor
                      v-model="selected.executionInstruction"
                      :editorOptions="vue2EditorOptionsWithEmojis"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.grams"
                      v-bind:label="$t('Grams')"
                      :type="'number'"
                      required
                      :rules="grams_mililiters_Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.mililiters"
                      v-bind:label="$t('Mililiters')"
                      :type="'number'"
                      required
                      :rules="grams_mililiters_Rules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_brands"
                      filled
                      required
                      :rules="requiredRules"
                      @change="changeBrandId"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.familyId"
                      v-bind:label="$t('brands.family')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_families"
                      filled
                      required
                      :rules="requiredRules"
                      @change="changeFamilyId"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_brand"
                      v-bind:label="$t('brands.sub_brand')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subbrands"
                      clearable
                      @change="changeSubBrand"
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_family"
                      v-bind:label="$t('brands.sub_family')"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subfamilies"
                      clearable
                      @change="changeSubFamily"
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_brand2"
                      v-bind:label="$t('brands.sub_brand') + ' 2'"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subbrands2"
                      clearable
                      @change="changeSubBrand2"
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_family2"
                      v-bind:label="$t('brands.sub_family') + ' 2'"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subfamilies2"
                      clearable
                      @change="changeSubFamily2"
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_brand3"
                      v-bind:label="$t('brands.sub_brand') + ' 3'"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subbrands3"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.sub_family3"
                      v-bind:label="$t('brands.sub_family') + ' 3'"
                      item-text="name"
                      item-value="id"
                      :items="filtered_subfamilies3"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="startDate_menu"
                      v-model="select_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startDate"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startDate"
                        no-title
                        @input="select_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="endDate_menu"
                      v-model="select_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endDate"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endDate"
                        no-title
                        scrollable
                        @input="select_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("brands.product_confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadExcelTemplateAPI('PRODUCTS')"
              class="mb-2 ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="loading-container"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="products"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalproducts"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <DialogProductCodes
      ref="dialogProductCodes"
      :importExcelProductCodesTemplatePathName="
        importExcelProductCodesTemplatePathName
      "
      :row="selectedRow"
      :update_table="update_table"
    />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import PictureInput from "vue-picture-input";
import download from "js-file-download";
import { Draggable } from "@he-tree/vue/vue2";
import "@he-tree/vue/style/default.css";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);
import DialogProductCodes from "./DialogProductCodes.vue";

export default {
  name: "Products",
  components: {
    PictureInput,
    Draggable,
    VueEditor,
    DialogProductCodes
  },
  data: function() {
    return {
      totalproducts: 0,
      products: [],
      productTypeValues: [],
      outOfStockTypeValues: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "id",
        "name",
        "code",
        "pro_ean",
        "description",
        "vat_code",
        "brand",
        "family",
        "units_per_fraction",
        "units_per_box",
        "status",
        "startDate",
        "endDate",
        "sub_brand1",
        "sub_brand2",
        "sub_brand3",
        "sub_family1",
        "sub_family2",
        "sub_family3",
        "productType",
        "centimeters",
        "productDepth",
        "salesArguments",
        "executionInstruction",
        "grams",
        "mililiters",
        "outOfStock"
      ],
      filterCriteria: {
        id: {},
        units_per_fraction: {},
        units_per_box: {},
        status: {},
        startDate: {},
        endDate: {},
        name: {},
        code: {},
        pro_ean: {},
        description: {},
        brand: {},
        family: {},
        sub_brand1: {},
        sub_brand2: {},
        sub_brand3: {},
        sub_family1: {},
        sub_family2: {},
        sub_family3: {},
        productType: {},
        centimeters: {},
        productDepth: {},
        salesArguments: {},
        executionInstruction: {},
        grams: {},
        mililiters: {},
        outOfStock: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      showEmpty: false,

      /********** create or edit dialog options **********/
      showEdit: false,
      dialogDelete: false,
      select_startDate_menu: false,
      select_endDate_menu: false,

      // search_brand: null,
      // isBrandSearching: false,
      filtered_brands: [],
      filtered_subbrands: [],
      filtered_subbrands2: [],
      filtered_subbrands3: [],

      // search_family: null,
      // isFamilySearching: false,
      filtered_families: [],
      filtered_subfamilies: [],
      filtered_subfamilies2: [],
      filtered_subfamilies3: [],
      // search_sub_brand: null,
      // isSubBrandSearching: false,
      // filtered_sub_brands: [],
      // search_sub_families: null,
      // isSubFamiliesSearching: false,
      // filtered_sub_families: [],

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      centimetersRules: [
        v =>
          !v ||
          v < 1000 ||
          "Incorrect value ! this value should be less than 1000."
      ],
      vatCodeRules: [
        v => !v || v < 100 || "Incorrect value ! this value should be 2 digits."
      ],
      unitsPerBoxRules: [
        v =>
          !v || v < 10000 || "Incorrect value ! this value should be 4 digits."
      ],
      unitsPerFractionRules: [
        v =>
          !v || v < 1000 || "Incorrect value ! this value should be 3 digits."
      ],
      proEanRules: [
        v =>
          !v ||
          v.length === 13 ||
          "Incorrect value ! this value should be 13 digits."
      ],
      grams_mililiters_Rules: [
        v =>
          !v ||
          v < 1000000 ||
          "Incorrect value ! this value should be 6 digits."
      ],
      outOfStock_Rules: [
        v =>
          !v ||
          v >= 0 ||
          "Incorrect value ! This value should be equal to or greater than 0."
      ],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** Product Codes **********/
      importExcelProductCodesTemplatePathName: null,
      selectedRow: null,

      /********************* Loading Spinner Overlay *****************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_productType() {
      return {
        key: "productType",
        title: this.$t("Type"),
        field: "productType",
        align: "left",
        width: 170,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.typeLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["productType"]}
                  dataList={this.productTypeValues}
                  filterCriteria={this.filterCriteria["productType"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "productType")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_centimeters() {
      return {
        key: "centimeters",
        title: this.$t("centimeters"),
        field: "centimeters",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["centimeters"]["from"]}
                  value={this.filterCriteria["centimeters"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["centimeters"]["to"]}
                  value={this.filterCriteria["centimeters"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "centimeters")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_productDepth() {
      return {
        key: "productDepth",
        title: this.$t("productDepth"),
        field: "productDepth",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["productDepth"]["from"]}
                  value={this.filterCriteria["productDepth"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["productDepth"]["to"]}
                  value={this.filterCriteria["productDepth"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "productDepth")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_productcodes() {
      return {
        key: "product_codes",
        title: `${this.$t("Code")} POS`,
        field: "product_codes",
        align: "center",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.showDialogProductCodes(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_eanPhoto() {
      return {
        key: "eanPhoto",
        title: `${this.$t("brands.Photos")} EAN`,
        field: "eanPhoto",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row.eanStaticPhoto !== null) {
            let thumbnail_url = this.getThumbnailUri(row.eanStaticPhoto);
            return (
              <img
                style="border: 1px solid #aaa; max-width: 100%"
                src={thumbnail_url}
                alt=""
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    columns() {
      return [
        {
          key: "a",
          title: "ID",
          field: "id",
          align: "left",
          width: 100,
          sortBy: "ASC",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["from"]}
                    value={this.filterCriteria["id"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["id"]["to"]}
                    value={this.filterCriteria["id"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "id")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "b",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          width: 150,
          sortBy: "",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "z",
          title: this.$t("brands.id_product_brand"),
          field: "code",
          align: "left",
          width: 180,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["code"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`code`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["code"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "code")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        // this.col_productType,
        {
          key: "c",
          title: this.$t("brands.ean"),
          field: "pro_ean",
          align: "left",
          width: 100,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["pro_ean"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`pro_ean`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["pro_ean"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "pro_ean")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        this.col_eanPhoto,
        {
          key: "d",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "f",
          title: this.$t("brands.brand"),
          field: "brand",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_brand1",
          title: `${this.$t("brands.sub_brand")} 1`,
          field: "sub_brand1",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_brands.map(sub_brand1_item => {
                    let height = 50;
                    height = parseInt(sub_brand1_item.leafcount) * height;
                    return (
                      <tr>
                        <td height={height > 0 ? height : "auto"}>
                          {sub_brand1_item.name}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_brand1"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_brand1`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_brand1"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_brand1")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_brand2",
          title: `${this.$t("brands.sub_brand")} 2`,
          field: "sub_brand2",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_brands.map(sub_brand1_item => {
                    if (sub_brand1_item.children.length > 0) {
                      return sub_brand1_item.children.map(sub_brand2_item => {
                        let height = 50;
                        height = parseInt(sub_brand2_item.leafcount) * height;
                        return (
                          <tr>
                            <td height={height > 0 ? height : 50}>
                              {sub_brand2_item.name}
                            </td>
                          </tr>
                        );
                      });
                    } else {
                      let height = 50;
                      return (
                        <tr>
                          <td height={height}>&nbsp;</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_brand2"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_brand2`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_brand2"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_brand2")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_brand3",
          title: `${this.$t("brands.sub_brand")} 3`,
          field: "sub_brand3",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_brands.map(sub_brand1_item => {
                    if (sub_brand1_item.children.length > 0) {
                      return sub_brand1_item.children.map(sub_brand2_item => {
                        if (sub_brand2_item.children.length > 0) {
                          return sub_brand2_item.children.map(
                            sub_brand3_item => {
                              let height = 50;
                              height =
                                parseInt(sub_brand3_item.leafcount) * height;
                              return (
                                <tr>
                                  <td height={height > 0 ? height : "auto"}>
                                    {sub_brand3_item.name}
                                  </td>
                                </tr>
                              );
                            }
                          );
                        } else {
                          let height = 50;
                          return (
                            <tr>
                              <td height={height}>&nbsp;</td>
                            </tr>
                          );
                        }
                      });
                    } else {
                      let height = 50;
                      return (
                        <tr>
                          <td height={height}>&nbsp;</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_brand3"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_brand3`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_brand3"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_brand3")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "h",
          title: this.$t("brands.family"),
          field: "family",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.family && row.family.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["family"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`family`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["family"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "family")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_family1",
          title: `${this.$t("brands.sub_family")} 1`,
          field: "sub_family1",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_families.map(sub_family1_item => {
                    let height = 50;
                    height = parseInt(sub_family1_item.leafcount) * height;
                    return (
                      <tr>
                        <td height={height > 0 ? height : "auto"}>
                          {sub_family1_item.name}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_family1"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_family1`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_family1"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_family1")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_family2",
          title: `${this.$t("brands.sub_family")} 2`,
          field: "sub_family2",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_families.map(sub_family1_item => {
                    if (sub_family1_item.children.length > 0) {
                      return sub_family1_item.children.map(sub_family2_item => {
                        let height = 50;
                        height = parseInt(sub_family2_item.leafcount) * height;
                        return (
                          <tr>
                            <td height={height > 0 ? height : "auto"}>
                              {sub_family2_item.name}
                            </td>
                          </tr>
                        );
                      });
                    } else {
                      let height = 50;
                      return (
                        <tr>
                          <td height={height > 0 ? height : "auto"}>&nbsp;</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_family2"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_family2`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_family2"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_family2")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "sub_family3",
          title: `${this.$t("brands.sub_family")} 3`,
          field: "sub_family3",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <table class="sub_data_table">
                <tbody>
                  {row.pro_sub_families.map(sub_family1_item => {
                    if (sub_family1_item.children.length > 0) {
                      return sub_family1_item.children.map(sub_family2_item => {
                        if (sub_family2_item.children.length > 0) {
                          return sub_family2_item.children.map(
                            sub_family3_item => {
                              let height = 50;
                              height =
                                parseInt(sub_family3_item.leafcount) * height;
                              return (
                                <tr>
                                  <td height={height > 0 ? height : "auto"}>
                                    {sub_family3_item.name}
                                  </td>
                                </tr>
                              );
                            }
                          );
                        } else {
                          let height = 50;
                          return (
                            <tr>
                              <td height={height > 0 ? height : "auto"}>
                                &nbsp;
                              </td>
                            </tr>
                          );
                        }
                      });
                    } else {
                      let height = 50;
                      return (
                        <tr>
                          <td height={height}>&nbsp;</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["sub_family3"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`sub_family3`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["sub_family3"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "sub_family3")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        this.col_centimeters,
        this.col_productDepth,
        {
          key: "j",
          title: this.$t("brands.units_per_fraction"),
          field: "units_per_fraction",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["units_per_fraction"]["from"]}
                    value={this.filterCriteria["units_per_fraction"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["units_per_fraction"]["to"]}
                    value={this.filterCriteria["units_per_fraction"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "units_per_fraction")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "k",
          title: this.$t("brands.units_per_box"),
          field: "units_per_box",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["units_per_box"]["from"]}
                    value={this.filterCriteria["units_per_box"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["units_per_box"]["to"]}
                    value={this.filterCriteria["units_per_box"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "units_per_box")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "l",
          title: this.$t("brands.vat"),
          field: "vat_code",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["vat_code"]}
                    on-input={value =>
                      (this.filterCriteria["vat_code"] = value)
                    }
                    placeholder="Search VAT"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "vat_code")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "salesArguments",
          title: this.$t("Sales Arguments"),
          field: "salesArguments",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div
                class="message_contents"
                domPropsInnerHTML={row.salesArguments}
              ></div>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["salesArguments"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`salesArguments`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["salesArguments"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "salesArguments")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "executionInstruction",
          title: this.$t("Execution Instruction"),
          field: "executionInstruction",
          align: "left",
          width: 230,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div
                class="message_contents"
                domPropsInnerHTML={row.executionInstruction}
              ></div>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["executionInstruction"]}
                    apiUrl={`products/getfilterlist`}
                    columnName={`executionInstruction`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["executionInstruction"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "executionInstruction")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "grams",
          title: this.$t("Grams"),
          field: "grams",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["grams"]["from"]}
                    value={this.filterCriteria["grams"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["grams"]["to"]}
                    value={this.filterCriteria["grams"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "grams")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "mililiters",
          title: this.$t("Mililiters"),
          field: "mililiters",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["mililiters"]["from"]}
                    value={this.filterCriteria["mililiters"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["mililiters"]["to"]}
                    value={this.filterCriteria["mililiters"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "mililiters")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "outOfStock",
          title: this.$t("Out of Stock"),
          field: "outOfStock",
          align: "left",
          width: 170,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.outOfStockLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["outOfStock"]}
                    dataList={this.outOfStockTypeValues}
                    filterCriteria={this.filterCriteria["outOfStock"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "outOfStock")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "m",
          title: this.$t("brands.start_date"),
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "n",
          title: this.$t("brands.end_date"),
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "o",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "p",
          title: this.$t("brands.Photos"),
          field: "photo",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.static !== null) {
              let thumbnail_url = this.getThumbnailUri(row.static);
              return (
                <img
                  style="border: 1px solid #aaa; max-width: 100%"
                  src={thumbnail_url}
                  alt=""
                />
              );
            } else {
              return <span>&nbsp;</span>;
            }
          }
        },
        this.col_productcodes,
        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    async update_table() {
      await this.getDataFromApi();
      if (this.selectedRow) {
        let foundOne = this.products.find(el => el.id === this.selectedRow.id);
        this.selectedRow = foundOne;
      }
    },
    showDialogProductCodes(row) {
      this.selectedRow = row;
      this.$refs.dialogProductCodes.showModal(row);
    },

    filter_tree_data(array, text) {
      const getChildren = (result, object) => {
        if (object.text === text) {
          result.push(object);
          return result;
        }
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getChildren, []);
          if (children.length) result.push({ ...object, children });
        }
        return result;
      };

      return array.reduce(getChildren, []);
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#loading-container"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "startDate" || key === "endDate") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (
            key === "units_per_fraction" ||
            key === "units_per_box" ||
            key === "centimeters" ||
            key === "productDepth" ||
            key === "grams" ||
            key === "id" ||
            key === "mililiters"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "products";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.products = resp.data;
        if (this.products.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalproducts = resp.total;
        this.productTypeValues = resp.productTypeValues;
        this.outOfStockTypeValues = resp.outOfStockTypeValues;
        this.importExcelProductCodesTemplatePathName =
          resp.importExcelProductCodesTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "products/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "products.xlsx");
      this.close();
    },

    async changeFamilyId() {
      this.filtered_subfamilies = [];
      this.selected.sub_family = [];
      this.filtered_subfamilies2 = [];
      this.selected.sub_family2 = [];
      this.filtered_subfamilies3 = [];
      this.selected.sub_family3 = [];
      await this.fetchlist_sub_family(this.selected.familyId);
    },

    async fetchlist_sub_family(familyId) {
      try {
        let fetch_url = "products/getfamilies?parent_ids=" + familyId;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subfamilies = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async changeSubFamily() {
      this.filtered_subfamilies2 = [];
      this.selected.sub_family2 = [];
      this.filtered_subfamilies3 = [];
      this.selected.sub_family3 = [];
      await this.fetchlist_sub_family2(this.selected.sub_family);
    },

    async fetchlist_sub_family2(sub_family) {
      try {
        let fetch_url = "products/getfamilies?parent_ids=" + sub_family;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subfamilies2 = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async changeSubFamily2() {
      this.filtered_subfamilies3 = [];
      this.selected.sub_family3 = [];
      await this.fetchlist_sub_family3(this.selected.sub_family2);
    },

    async fetchlist_sub_family3(sub_family2) {
      try {
        let fetch_url = "products/getfamilies?parent_ids=" + sub_family2;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subfamilies3 = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async changeBrandId() {
      this.filtered_subbrands = [];
      this.selected.sub_brand = [];
      this.filtered_subbrands2 = [];
      this.selected.sub_brand2 = [];
      this.filtered_subbrands3 = [];
      this.selected.sub_brand3 = [];
      await this.fetchlist_sub_brand(this.selected.brandId);
      await this.fetchlist_families(this.selected.brandId);
    },

    async fetchlist_families(brandId) {
      try {
        let fetch_url = "products/getfamilies?parent_ids=0&brandId=" + brandId;
        const resp = await ApiService.get(fetch_url);
        this.filtered_families = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchlist_sub_brand(brandId) {
      try {
        let fetch_url = "products/getbrands?parent_ids=0&brandId=" + brandId;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subbrands = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async changeSubBrand() {
      this.filtered_subbrands2 = [];
      this.selected.sub_brand2 = [];
      this.filtered_subbrands3 = [];
      this.selected.sub_brand3 = [];
      await this.fetchlist_sub_brand2(this.selected.sub_brand);
    },

    async fetchlist_sub_brand2(sub_brand) {
      try {
        let fetch_url = "products/getbrands?parent_ids=" + sub_brand;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subbrands2 = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async changeSubBrand2() {
      this.filtered_subbrands3 = [];
      this.selected.sub_brand3 = [];
      await this.fetchlist_sub_brand3(this.selected.sub_brand2);
    },

    async fetchlist_sub_brand3(sub_brand2) {
      try {
        let fetch_url = "products/getbrands?parent_ids=" + sub_brand2;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subbrands3 = resp.data;
      } catch (error) {
        console.log(error);
      }
    },

    async createSelectedModel(item) {
      try {
        let fetch_url = "products/getbrands?parent_ids=0&is_main_brand=1";
        const resp = await ApiService.get(fetch_url);
        this.filtered_brands = resp.data;
      } catch (error) {
        console.log(error);
      }
      // try {
      //   let fetch_url = "products/getfamilies?parent_ids=0";
      //   const resp = await ApiService.get(fetch_url);
      //   this.filtered_families = resp.data;
      // } catch (error) {
      //   console.log(error);
      // }
      console.log(item);
      let model_startDate = _.get(item, "startDate");
      let model_endDate = _.get(item, "endDate");
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        code: _.get(item, "code"),
        pro_ean: _.get(item, "pro_ean"),
        description: _.get(item, "description"),
        units_per_fraction: _.get(item, "units_per_fraction"),
        units_per_box: _.get(item, "units_per_box"),
        vat_code: _.get(item, "vat_code"),
        centimeters: _.get(item, "centimeters"),
        productDepth: _.get(item, "productDepth"),
        productType: _.get(item, "productType"),

        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        // sub_brands_treeData: _.get(item, "sub_brands_treeData"),
        familyId: _.get(item, "familyId"),
        family: _.get(item, "family"),
        // sub_families_treeData: _.get(item, "sub_families_treeData"),

        sub_brand: _.get(item, "sub_brand"),
        sub_brand2: _.get(item, "sub_brand2"),
        sub_brand3: _.get(item, "sub_brand3"),
        sub_family: _.get(item, "sub_family"),
        sub_family2: _.get(item, "sub_family2"),
        sub_family3: _.get(item, "sub_family3"),

        mililiters: _.get(item, "mililiters"),
        outOfStock: _.get(item, "outOfStock"),
        grams: _.get(item, "grams"),
        salesArguments: _.get(item, "salesArguments"),
        executionInstruction: _.get(item, "executionInstruction"),

        startDate: model_startDate,
        endDate: model_endDate,
        status:
          _.get(item, "status") !== undefined
            ? _.get(item, "status")
            : "active",
        _extended: {
          img: null,
          static: _.get(item, "static")
        },
        _ean_extended: {
          img: null,
          static: _.get(item, "eanStaticPhoto")
        }
      };
      console.log(model);
      if (!model.id) {
        model.productType = "BOTH";
        model.outOfStock = "YES";
      }
      if (model.brandId !== undefined) {
        this.fetchlist_sub_brand(model.brandId);
        await this.fetchlist_families(model.brandId);
      }
      if (model.sub_brand !== undefined) {
        if (model.sub_brand.length > 0) {
          model.sub_brand = model.sub_brand[0];
        }
        this.fetchlist_sub_brand2(model.sub_brand);
      }
      if (model.sub_brand2 !== undefined) {
        if (model.sub_brand2.length > 0) {
          model.sub_brand2 = model.sub_brand2[0];
        }
        if (model.sub_brand3 !== undefined && model.sub_brand3.length > 0) {
          model.sub_brand3 = model.sub_brand3[0];
        }
        this.fetchlist_sub_brand3(model.sub_brand2);
      }
      if (model.familyId !== undefined) {
        this.fetchlist_sub_family(model.familyId);
      }
      if (model.sub_family !== undefined) {
        if (model.sub_family.length > 0) {
          model.sub_family = model.sub_family[0];
        }
        this.fetchlist_sub_family2(model.sub_family);
      }
      if (model.sub_family2 !== undefined) {
        if (model.sub_family2.length > 0) {
          model.sub_family2 = model.sub_family2[0];
        }
        if (model.sub_family3 !== undefined && model.sub_family3.length > 0) {
          model.sub_family3 = model.sub_family3[0];
        }
        this.fetchlist_sub_family3(model.sub_family2);
      }
      if (model.id === undefined && this.$refs["pictureInput"] !== undefined) {
        this.$refs["pictureInput"].removeImage();
      }
      if (
        model.id === undefined &&
        this.$refs["eanPictureInput"] !== undefined
      ) {
        this.$refs["eanPictureInput"].removeImage();
      }
      return model;
    },

    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected._extended.img = null;
      this.selected._ean_extended.img = null;
      this.showEdit = true;
      window.setTimeout(() => {
        this.$refs.pictureInput.onResize();
        this.$refs.eanPictureInput.onResize();
      }, 200); //issue discussed here: https://github.com/alessiomaffeis/vue-picture-input/issues/50#issuecomment-507013741
    },

    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },

    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },

    onPicture(img) {
      this.selected._extended.img = this.$refs["pictureInput"].file;
    },

    onPictureEan(img) {
      this.selected._ean_extended.img = this.$refs["eanPictureInput"].file;
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    async onSaveClick(item) {
      // this.selected._extended.img = this.$refs["pictureInput"].file;
      // this.selected._ean_extended.img = this.$refs["eanPictureInput"].file;
      if (this.$refs.form.validate()) {
        if (item.startDate && item.endDate) {
          if (item.startDate > item.endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        let body = _.omit(item, ["_extended", "_ean_extended"]);
        let img = item._extended.img;
        if (img && !img.name?.includes(item._extended.static?.file)) {
          let { data } = await ApiService.uploadImage(img);
          // logInfo("imagen subida");
          body.photos = data.id;
        }
        let img_ean = item._ean_extended.img;
        if (
          img_ean &&
          !img_ean.name?.includes(item._ean_extended.static?.file)
        ) {
          let { data } = await ApiService.uploadImage(img_ean);
          // logInfo("imagen subida");
          body.eanPhoto = data.id;
        }
        console.log(body);
        if (body.id) {
          await ApiService.put(`products/${body.id}`, body);
        } else {
          await ApiService.post(`products`, body);
        }
        logInfo("producto actualizada");
        this.showEdit = false;
        this.getDataFromApi();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`products/${this.deleteItemId}`).then(() => {
          logInfo("Producto eliminada");
          this.getDataFromApi();
        });
      }
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        [
          "startDate",
          "endDate",
          "units_per_fraction",
          "units_per_box",
          "centimeters",
          "productDepth",
          "grams",
          "id",
          "mililiters"
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        [
          "status",
          "name",
          "code",
          "pro_ean",
          "description",
          "salesArguments",
          "executionInstruction",
          "brand",
          "family",
          "sub_brand1",
          "sub_brand2",
          "sub_brand3",
          "sub_family1",
          "sub_family2",
          "sub_family3",
          "outOfStock",
          "productType"
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey] = {};
      } else {
        this.filterCriteria[cancelFilterKey] = null;
      }
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    },

    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },

    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(`products/uploadexcel`, data, {
            headers: {
              "Content-Type": `multipart/form-data;`
            }
          });
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}, ${response?.error_message}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isLoadingSpinner = false;
          this.isSelecting = false;
          this.getDataFromApi();
        } catch (error) {
          this.isLoadingSpinner = false;
          this.isSelecting = false;
        }
      }
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

input[type="date"]:focus:before {
  content: "" !important;
}

input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
.tree-node-inner {
  font-size: 15px;
  color: #000;
  font-weight: bold;
}

table.sub_data_table {
  width: 100%;
}
table.sub_data_table tbody tr td {
  border: none !important;
}
.ve-table
  .ve-table-container
  .ve-table-border-y
  table.sub_data_table
  tbody
  tr
  td:first-child {
  border: none !important;
  border-bottom: 1px solid #9f9f9f !important;
}
.ve-table.ve-table-border-around
  .ve-table-container
  table.ve-table-content.ve-table-border-x
  table.sub_data_table
  tr:last-child
  > td {
  border: none !important;
}
</style>
